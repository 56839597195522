<template>
  <sub-nav :pages="pages" />
</template>

<script>
export default {
  name: 'SubNavEstimating',
  data() {
    return {}
  },
  computed: {
    pages() {
      return [
        {
          title: 'Proposal presentations',
          icon: 'keynote',
          to: '/templates/presentations',
          active: [new RegExp(/templates$/)]
        },
        {
          title: 'Document/PDF',
          icon: 'file-pdf',
          to: '/templates/documents'
        }
      ]
    }
  },
  props: {},
  components: {}
}
</script>
